<template>
    <div id="notices">


        <el-image class="pages-header" :src='require("../../assets/actbg@3x.png")' fit="cover">
        </el-image>


        <div class="news-content">
            <div class="content-title">
                <p><i class="el-icon-s-home"></i> 通知公告</p>
            </div>
            <div class="content-warp">
                <div class="content-left news">
                    <!-- 头条新闻 -->
                    <div class="top-new" @click="toDetail('通知公告',0)">
                        <el-image :src=topMsg.img fit="cover">
                        </el-image>
                        <div class="top-new-text">
                            <p class="date">{{topMsg.date[0]}}.{{topMsg.date[1]}}.{{topMsg.date[2]}}</p>
                            <p class="title">{{topMsg.title}}</p>
                            <div class="news-content content">{{topMsg.summary}}</div>
                        </div>
                    </div>
                    <!-- 其他新闻 -->
                    <div class="other-news">
                        <ul>
                            <li v-for="(item, index) in notices" :key="index" @click="toDetail('通知公告',index+1)">
                                <div class="date">
                                    <p style="font-size: 30px;">{{item.date[2]}}</p>
                                    <p style="font-size: 16px;">
                                        {{item.date[0]}}.{{item.date[1]}}
                                    </p>
                                </div>
                                <div class="other-news-text">
                                    <div class="title">{{item.title}}<i class="el-icon-arrow-right"
                                                                        style="float: right"></i></div>
                                    <div class="news-content">{{item.summary}}</div>
                                </div>
                            </li>
                        </ul>
                        <!--                        <el-pagination style="text-align: center; padding: 20px 0 20px 0"-->
                        <!--                                       layout="prev, pager, next"-->
                        <!--                                       :total="10">-->
                        <!--                        </el-pagination>-->
                    </div>
                </div>
                <div class="content-right">
                    <div class="right-first-warp">
                        <div class="module-title">
                            <p class="title-textC">安全新闻</p>
                            <p class="title-textE">SAFETY NEWS
                                <router-link to="/news"><span class="more">MORE</span></router-link>
                            </p>
                        </div>
                        <ul class="msg-ul">
                            <li v-for="(item, index) in news" :key="index" @click="toDetail('安全新闻',index)">
                                {{item.title}}
                            </li>
                        </ul>
                    </div>
                    <div class="right-second-warp">
                        <div class="module-title">
                            <p class="title-textC">政策法规</p>
                            <p class="title-textE">POLICIES AND REGULATIONS
                                <router-link to="/rules"><span class="more">MORE</span></router-link>
                            </p>
                        </div>
                        <ul class="msg-ul">
                            <li v-for="(item, index) in rules" :key="index" @click="toDetail('政策法规',index)">
                                {{item.title}}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "news",
        components: {},
        created() {
            this.getNews();
            this.getRules();
            this.getNotices();
        },
        mounted() {
        },
        data() {
            return {
                news: [],
                rules: [],
                notices: [],
                topMsg: {}
            };
        },
        methods: {
            getNews() {
                let data = [{
                    "title": "我司首席安全顾问罗宏森教授受邀参加高新区应急体系建设“十四五” 规划专题研讨暨专家评审会",
                    "img": require("../../assets/news/topNew1.png"),
                    "date": "2021/06/02",
                    "summary": "6月1日上午，成都高新区召开应急体系建设“十四五”规划专题研讨暨专家评审会。会议由党工委委员、管委会副主任陈卫东主持，邀请了四川师范大学公共安全与应急研究院院长罗宏森教授、西南交通大学马剑教授、中国工程物理研究院材料物理研究所张敏高级政工师，成都市应急管理局、高新区应急局、高新区发展规划局、高新消防救援大队、相关负责人参加了会议。"
                }, {
                    "title": "教育部颁布《未成年人学校保护规定》",
                    "date": "2021/06/01",
                    "summary": "为贯彻落实新修订的未成年人保护法，在广泛调研、公开征求意见基础上，教育部部长陈宝生于6月1日签发第50号教育部令，颁布",
                    "publisher": "心安理得安全科技研究院"
                }, {
                    "title": "关于征求《关于加强学校安全风险防控体系 建设的意见（第二次征求意见稿）》 意见建议的通知",
                    "date": "2021/05/31",
                    "summary": "2021 年 5 月 22 日，市委常委会第 180 次会议审议了《关于加强学校安全风险防控体系建设的意见（送审稿）》（以下简称",
                    "publisher": "成都市教育局",
                }, {
                    "title": "教育部办公厅关于做好预防中小学生溺水事故工作的通知",
                    "date": "2021/05/21",
                    "summary": "随着各地气温迅速上升，天气日渐炎热，汛期即将来临，学生溺水事故进入多发高发期。日前，湖北、湖南、广西等地先后发生多起溺水事件，造成多名中小学生死亡，令人痛心。为做好预防学生溺水事故有关工作，保障广大中小学生生命安全，现将有关事项通知如下",
                    "publisher": "心安理得安全科技研究院"
                }];
                this.news = data;
            },
            getRules() {
                let data = [{
                    "title": "《义务教育学校管理标准》",
                    "img": require("../../assets/topNew.png"),
                    "date": "2021/05/01",
                    "summary": "全国中小学幼儿园召开安全工作视频会 学校安全工作需要全国中小学幼儿园 开安全工作视频会 学校安全工作需要全国中小学幼儿园召开安"
                }, {
                    "title": "《中小学幼儿园安全管理办法》",
                    "img": require("../../assets/topNew.png"),
                    "date": "2021/05/01",
                    "summary": "全国中小学幼儿园召开安全工作视频会 学校安全工作需要全国中小学幼儿园 开安全工作视频会 学校安全工作需要全国中小学幼儿园召开安"
                }, {
                    "title": "习近平：积极推进我国应急管理体系和能力现代...",
                    "img": require("../../assets/topNew.png"),
                    "date": "2021/05/01",
                    "summary": "全国中小学幼儿园召开安全工作视频会 学校安全工作需要全国中小学幼儿园 开安全工作视频会 学校安全工作需要全国中小学幼儿园召开安"
                }, {
                    "title": "台州2岁男童误食磁力珠导致肠道破损8处，被送往...",
                    "img": require("../../assets/topNew.png"),
                    "date": "2021/05/01",
                    "summary": "全国中小学幼儿园召开安全工作视频会 学校安全工作需要全国中小学幼儿园 开安全工作视频会 学校安全工作需要全国中小学幼儿园召开安"
                }];

                this.rules = data;
            },
            getNotices() { // 获取学校列表
                let data = [{
                    "title": "2021年中小学生（幼儿）预防溺水专题教育",
                    "img": require("../../assets/topNew.png"),
                    "date": "2021/05/01",
                    "summary": "点击图片参与学习 溺水是造成中小学生意外死亡的第一杀手。天气逐渐变热，未成年人涉水活动增多，溺水事故又进入高发期。为了给学生传授防溺水相关知识和技能，不断加强学生安全意识和自我保护意识，提高学生的避险防灾和自救能力，严防意外事故的发生，请广大师生积极参与以“珍爱生命，谨防溺水”为主题的..."
                },
                    {
                        "title": "全国中小学幼儿园召开安全工作视频会 学校安全工作实施",
                        "img": require("../../assets/topNew.png"),
                        "date": "2021/05/01",
                        "summary": "全国中小学幼儿园召开安全工作视频会 学校安全工作需要全国中小学幼儿园 开安全工作视频会 学校安全工作需要全国中小学幼儿园召开安"
                    }, {
                        "title": "【家校提醒】“五一”小长假，安全不停歇！",
                        "img": require("../../assets/topNew.png"),
                        "date": "2021/05/01",
                        "summary": "全国中小学幼儿园召开安全工作视频会 学校安全工作需要全国中小学幼儿园 开安全工作视频会 学校安全工作需要全国中小学幼儿园召开安"
                    }, {
                        "title": "怀化市沅陵一男孩被困岩壁 公安成功救助",
                        "img": require("../../assets/topNew.png"),
                        "date": "2021/05/01",
                        "summary": "全国中小学幼儿园召开安全工作视频会 学校安全工作需要全国中小学幼儿园 开安全工作视频会 学校安全工作需要全国中小学幼儿园召开安"
                    }, {
                        "title": "台州2岁男童误食磁力珠导致肠道破损8处，被送往医院",
                        "img": require("../../assets/topNew.png"),
                        "date": "2021/05/01",
                        "summary": "全国中小学幼儿园召开安全工作视频会 学校安全工作需要全国中小学幼儿园 开安全工作视频会 学校安全工作需要全国中小学幼儿园召开安"
                    }
                ];

                data.forEach(item => {
                    item.date = item.date.split(/-|[.]|[/]/);
                    // console.log(item.date)
                    // return date;
                })

                this.topMsg = data.shift();
                this.notices = data;
            },
            toDetail(title, index) {
                // console.log(title,index);
                this.$router.push({path: '/detail', query: {title: title, id: index}});
            }
        }
    }
</script>

<style scoped lang="less">
    #notices {
        .router-link-active {
            text-decoration: none !important;
            color: #888888 !important;
        }

        a {
            text-decoration: none !important;
            color: #888888 !important;
        }

        font-size: 14px;
        font-family: Microsoft YaHei;
        /*font-weight: bold;*/
        color: #666666;
        line-height: 36px;

        .pages-header {
            display: block;
            width: 100%;
        }

        .news-content {
            width: 100%;

            .content-title {
                width: 100%;
                height: 87px;
                padding: 0 100px 0 100px;
                border-bottom: 1px solid #E5E5E5;
                display: flex;
                align-items: center;
                opacity: 0.8;
                font-weight: bold;

                p {
                    margin: 0;
                }
            }

            .content-warp {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                padding: 70px 100px 0 100px;
                justify-content: space-between;

                .content-left {
                    width: 70%;
                    /*min-width: 350px;*/

                    .news-content {
                        font-size: 14px;
                        font-weight: 400;
                        color: #808080;
                        line-height: 24px;
                    }

                    // 头条新闻 start
                    .top-new {
                        display: flex;
                        flex-wrap: wrap;
                        cursor: pointer;

                        .el-image {
                            width: 300px;
                            height: 230px;
                            /*float: left;*/
                        }

                        .top-new-text {
                            padding: 30px 37px 41px 37px;
                            background: #f4f4f4;
                            width: ~"calc(100% - 300px)";

                            .date {
                                font-size: 30px;
                                font-family: DINCond-Bold;
                                font-weight: 400;
                                color: #808080;
                                line-height: 1;
                            }

                            .title {
                                font-size: 22px;
                                color: #15A993;
                                line-height: 30px;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }

                            .content {
                                height: 67px;
                                overflow: hidden;
                            }
                        }
                    }

                    .other-news {
                        border-left: 1px solid #f4f4f4;
                        float: right;
                        width: ~"calc(100% - 300px)";

                        li {
                            padding: 30px 37px 41px 37px;
                            border-bottom: 1px solid #f4f4f4;

                            .date {
                                margin-right: 30px;
                                font-size: 36px;
                                font-family: DINCond-Bold;
                                font-weight: 400;
                                color: #808080;
                                line-height: 1;
                                float: left;
                            }

                            .other-news-text {
                                .title {
                                    font-size: 16px;
                                    line-height: 24px;
                                    color: #666666;
                                    font-weight: bold;
                                    margin-bottom: 24px;
                                    overflow: hidden;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                }
                            }
                        }

                    }
                }

                .content-right {
                    width: 25%;
                    /*border: 1px solid green;*/

                    .module-title {
                        p {
                            margin: 0;
                        }

                        .title-textC {
                            font-size: 26px;
                            color: #000000;
                        }

                        .title-textE {
                            font-size: 14px;
                            color: #888888;

                            .more {
                                float: right;
                            }
                        }
                    }

                    .msg-ul {
                        font-size: 14px;
                        color: #666666;
                        margin-bottom: 5vw;

                        li {
                            width: ~"calc(100% - 12px)";
                            margin-left: 12px;
                            border-bottom: 1px dotted #cccccc;
                            list-style-image: url("../../assets/liStyleImage@2x.png");
                        }
                    }

                    .pic-news-warp {
                        .pic-news {
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: space-between;
                            margin: 0;

                            .pic-news-card {
                                width: 200px;
                                margin: 10px 0 20px 0;

                                .el-image {
                                    width: 100%;
                                    height: 150px;
                                }

                                .card-text {
                                    width: 100%;
                                    margin-top: -13px;
                                    padding: 0 20px 0 20px;
                                    line-height: 3;
                                    background: #F0F0F0;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                }
                            }
                        }
                    }
                }
            }
        }


        @media (max-width: 900px) {
            .content-title {
                padding-left: 50px !important;
                height: 15vw !important;
            }

            .content-warp {
                padding: 5vw !important;

                .content-left {
                    width: 100% !important;
                    /*overflow:hidden;*/

                    .top-new {
                        clear: both !important;

                        .top-new-text {
                            width: 100% !important;
                        }
                    }

                    .other-news {
                        width: 100% !important;
                        clear: both !important;

                        i {
                            display: none;
                        }
                    }
                }

                .content-right {
                    width: 100% !important;
                    display: none;
                }
            }
        }
    }


</style>
